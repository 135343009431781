<template>
  <div class="materials">
    <UserData />
    <MaterialsFilter class="d-xl-none" />
    <div v-if="recommendations && Object.keys(recommendations).length">
      <div
        v-if="
          user &&
          user.specialities &&
          user.specialities.length &&
          user &&
          user.diseases &&
          user.diseases.length
        "
      >
        <div class="materials__title mb-4">Материалы для вас:</div>
        <div
          @click="openFilter"
          class="
            button button_pink
            px-12
            d-inline-flex
            mb-4
            materials__filter-button
            d-xl-none
          "
        >
          <svg
            class="mr-2"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.5078 12H21.6191" stroke="white" />
            <path d="M3.38281 12H15.0116" stroke="white" />
            <path d="M10.4531 5.98828H21.6206" stroke="white" />
            <path d="M3.38281 5.98828H5.96281" stroke="white" />
            <path d="M13.625 18.0117H21.62" stroke="white" />
            <path d="M3.38281 18.0117H9.12781" stroke="white" />
            <path
              d="M8.20703 8.125C9.44967 8.125 10.457 7.11764 10.457 5.875C10.457 4.63236 9.44967 3.625 8.20703 3.625C6.96439 3.625 5.95703 4.63236 5.95703 5.875C5.95703 7.11764 6.96439 8.125 8.20703 8.125Z"
              stroke="white"
            />
            <path
              d="M17.2578 14.25C18.5005 14.25 19.5078 13.2426 19.5078 12C19.5078 10.7574 18.5005 9.75 17.2578 9.75C16.0152 9.75 15.0078 10.7574 15.0078 12C15.0078 13.2426 16.0152 14.25 17.2578 14.25Z"
              stroke="white"
            />
            <path
              d="M11.375 20.2617C12.6176 20.2617 13.625 19.2544 13.625 18.0117C13.625 16.7691 12.6176 15.7617 11.375 15.7617C10.1324 15.7617 9.125 16.7691 9.125 18.0117C9.125 19.2544 10.1324 20.2617 11.375 20.2617Z"
              stroke="white"
            />
          </svg>
          Фильтр
        </div>
        <MaterialsCategories class="mb-7" />
        <MaterialsSlider
          :key="`text_${recommendationsList.text_materials.length}`"
          v-if="
            recommendationsList.text_materials &&
            recommendationsList.text_materials.length
          "
          :title="'Публикации'"
          class="mb-12"
          :count="recommendationsList.text_materials.length"
        >
          <template #items>
            <Material
              v-for="(item, i) in recommendationsList.text_materials"
              :item="item"
              :key="`text${i}`"
              :page="'TextDetail'"
              @watchLater="
                watchLater(
                  item.watch_later ? 'DELETE' : 'POST',
                  item.slug,
                  'text_material'
                )
              "
              @favorite="
                favorite(
                  item.favorite ? 'DELETE' : 'POST',
                  item.slug,
                  'text_material'
                )
              "
            />
          </template>
        </MaterialsSlider>
        <MaterialsSlider
          :key="`podcasts_${recommendationsList.podcasts.length}`"
          v-if="
            recommendationsList.podcasts && recommendationsList.podcasts.length
          "
          :title="'Подкасты'"
          class="mb-12"
          :count="recommendationsList.podcasts.length"
        >
          <template #items>
            <Material
              v-for="(item, i) in recommendationsList.podcasts"
              :item="item"
              :key="`pod${i}`"
              :page="'PodcastDetail'"
              @watchLater="
                watchLater(
                  item.watch_later ? 'DELETE' : 'POST',
                  item.slug,
                  'podcast'
                )
              "
              @favorite="
                favorite(
                  item.favorite ? 'DELETE' : 'POST',
                  item.slug,
                  'podcast'
                )
              "
            />
          </template>
        </MaterialsSlider>
        <MaterialsSlider
          :key="`video_${recommendationsList.video_materials.length}`"
          v-if="
            recommendationsList.video_materials &&
            recommendationsList.video_materials.length
          "
          :title="'Видеоматериалы'"
          class="mb-12"
          :count="recommendationsList.video_materials.length"
        >
          <template #items>
            <Material
              v-for="(item, i) in recommendationsList.video_materials"
              :item="item"
              :key="`vid${i}`"
              :page="'VideoDetail'"
              @watchLater="
                watchLater(
                  item.watch_later ? 'DELETE' : 'POST',
                  item.slug,
                  'video_material'
                )
              "
              @favorite="
                favorite(
                  item.favorite ? 'DELETE' : 'POST',
                  item.slug,
                  'video_material'
                )
              "
            />
          </template>
        </MaterialsSlider>
        <MaterialsSlider
          :key="`disease_${recommendationsList.diseases.length}`"
          v-if="
            recommendationsList.diseases && recommendationsList.diseases.length
          "
          :title="'Обновления в заболеваниях'"
          class="mb-12"
          :count="recommendationsList.diseases.length"
        >
          <template #items>
            <DiseaseUpdate
              v-for="(disease, i) in recommendationsList.diseases"
              :key="`updis${i}`"
              :item="disease"
            />
          </template>
        </MaterialsSlider>
        <MaterialsSlider
          :key="`drugs_${recommendationsList.drugs.length}`"
          v-if="recommendationsList.drugs && recommendationsList.drugs.length"
          :title="'Препараты'"
          class="mb-12"
          :count="recommendationsList.drugs.length"
        >
          <template #items>
            <Medication
              v-for="(drug, i) in recommendationsList.drugs"
              :key="`drug${i}`"
              :item="drug"
              @watchLater="
                watchLater(
                  item.watch_later ? 'DELETE' : 'POST',
                  item.slug,
                  'drug'
                )
              "
              @favorite="
                favorite(item.favorite ? 'DELETE' : 'POST', item.slug, 'drug')
              "
            />
          </template>
        </MaterialsSlider>

        <div
          v-if="recommendationsList.events && recommendationsList.events.length"
        >
          <div class="materials__item-title mb-3">Мероприятия</div>
          <Event
            v-for="i in eventsListLength"
            :key="`ev${i}`"
            :event="recommendationsList.events[i - 1]"
            class="mb-6"
            large
            style="background-color: #fff"
          />
          <div
            class="materials-page__load button button_pink"
            v-if="recommendationsList.events.length > listLength"
            @click="loadMore()"
          >
            Загрузить ещё
            <span class="ml-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 12.7501L4.5 12.0001H3V12.7501H4.5ZM19.5 12.7501C19.5 16.8923 16.1421 20.2501 12 20.2501L12 21.7501C16.9706 21.7501 21 17.7207 21 12.7501L19.5 12.7501ZM12 20.2501C7.85786 20.2501 4.5 16.8923 4.5 12.7501H3C3 17.7207 7.02944 21.7501 12 21.7501L12 20.2501ZM12 5.25012C16.1421 5.25012 19.5 8.60799 19.5 12.7501L21 12.7501C21 7.77956 16.9706 3.75012 12 3.75012V5.25012ZM7.56864 6.69856C8.8101 5.78779 10.3412 5.25012 12 5.25012V3.75012C10.0114 3.75012 8.17143 4.39596 6.68136 5.48912L7.56864 6.69856Z"
                  fill="#B36697"
                />
                <path
                  d="M8.08391 1.05776L6.37094 6.61929L11.9325 8.33225"
                  stroke="#B36697"
                  stroke-width="1.5"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div
        class="favorites__list-container"
        v-if="
          user &&
          user.specialities &&
          user.specialities.length &&
          user &&
          user.diseases &&
          user.diseases.length &&
          !notEmpty
        "
      >
        <div class="favorites__title">Здесь пока пусто</div>
        <div class="favorites__descr">
          Чтобы увидеть список материалов для вас - выберите хотя бы одну
          терапевтическую область и одну нозологию
        </div>
      </div>

      <div
        class="favorites__list-container"
        v-else-if="
          user &&
          user.specialities &&
          !user.specialities.length &&
          user &&
          user.diseases &&
          !user.diseases.length
        "
      >
        <div class="favorites__title">Здесь пока пусто</div>
        <div class="favorites__descr">
          Чтобы увидеть Материалы для вас - пройдите тест
        </div>
        <div
          class="favorites__btn button button_pink mt-6"
          @click="toSettings({ name: 'MaterialsSettings' })"
        >
          Настроить Мой AZ-MOST
        </div>
      </div>
    </div>
    <div v-else class="preloader">
      <Preloader class="" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import UserData from "../../components/pageComponents/cabinet/UserData.vue";
import MaterialsCategories from "../../components/pageComponents/cabinet/MaterialsCategories.vue";
import MaterialsSlider from "../../components/pageComponents/cabinet/MaterialsSlider.vue";
import Material from "../../components/pageComponents/Material.vue";
import Event from "../../components/main/events/Event.vue";
import Medication from "@/components/pageComponents/Medication.vue";
import DiseaseUpdate from "@/components/pageComponents/DiseaseUpdate.vue";
import { bus } from "@/main";
import MaterialsFilter from "../../components/pageComponents/cabinet/MaterialsFilter.vue";
import Preloader from "@/components/Preloader.vue";
export default {
  components: {
    UserData,
    MaterialsCategories,
    MaterialsSlider,
    Material,
    Event,
    Medication,
    DiseaseUpdate,
    MaterialsFilter,
    Preloader,
  },
  name: "Materials",
  data: () => ({
    count: 3,
    listLength: 6,
  }),
  computed: {
    ...mapGetters(["user", "recommendations"]),
    eventsListLength() {
      return this.listLength > this.recommendationsList.events.length
        ? this.recommendationsList.events.length
        : this.listLength;
    },
    currentNosologys: {
      get() {
        return this.$store.state.materialsFilter.materialsNosology;
      },
      set(value) {
        this.$store.commit("updateMatNos", value);
      },
    },
    currentDiseases: {
      get() {
        return this.$store.state.materialsFilter.materialsDisease;
      },
      set(value) {
        this.$store.commit("updateMatDis", value);
      },
    },
    notEmpty() {
      return Object.keys(this.recommendationsList).some(
        (r) => this.recommendationsList[r].length
      );
    },
    notEmptyTabs() {
      return Object.keys(this.recommendationsList).filter(
        (el) => this.recommendationsList[el].length
      );
    },
    recommendationsList() {
      return {
        diseases: this.recommendations.diseases
          .filter(
            (el) =>
              this.currentDiseases.includes(el.id) &&
              this.currentNosologys.includes(el.parent_id)
          )
          .map(function (item) {
            let created_arr = item.content_created
              .filter(
                (el) => !item.content_updated.find((up) => up.id === el.id)
              )
              .map(function (created) {
                created.updated_at = null;
                return {
                  ...created,
                  ...item,
                  name: created.title,
                  label: "Новый раздел",
                  child_id: created.id,
                };
              });
            let updated_arr = item.content_updated.map(function (updated) {
              updated.created_at = null;
              return {
                ...updated,
                ...item,
                name: updated.title,
                label: "Раздел обновлен",
                child_id: updated.id,
              };
            });
            return created_arr.concat(updated_arr);
          })
          .flat(1),
        drugs: this.recommendations.drugs.filter(
          (el) =>
            (!el.nosology.length ||
              el.nosology.some((r) => this.currentNosologys.includes(r.id))) &&
            (!el.disease.length ||
              el.disease.some((r) => this.currentDiseases.includes(r.id)))
        ),
        events: this.recommendations.events.filter((el) =>
          el.nosology.some((r) => this.currentNosologys.includes(r.id))
        ),
        podcasts: this.recommendations.podcasts.filter(
          (el) =>
            el.nosology.some((r) => this.currentNosologys.includes(r.id)) &&
            el.disease.some((r) => this.currentDiseases.includes(r.id))
        ),
        text_materials: this.recommendations.text_materials.filter(
          (el) =>
            el.nosology.some((r) => this.currentNosologys.includes(r.id)) &&
            el.disease.some((r) => this.currentDiseases.includes(r.id))
        ),
        video_materials: this.recommendations.video_materials.filter(
          (el) =>
            el.nosology.some((r) => this.currentNosologys.includes(r.id)) &&
            el.disease.some((r) => this.currentDiseases.includes(r.id))
        ),
      };
    },
  },
  methods: {
    ...mapActions([
      "getRecommendations",
      "fetchVideoFavorite",
      "fetchPodcastFavorite",
      "fetchTextFavorite",
      "fetchMedicationFavorite",
      "fetchVideoWatchLater",
      "fetchPodcastWatchLater",
      "fetchTextWatchLater",
      "fetchMedicationWatchLater",
    ]),
    toSettings(to) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "set my AZ-MOST up", {
          "set my AZ-MOST up": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(to).catch(() => {});
    },
    loadMore() {
      this.listLength =
        this.listLength + this.count > this.recommendationsList.events.length
          ? this.recommendationsList.events.length
          : this.listLength + this.count;
    },
    async watchLater(method, slug, type) {
      switch (type) {
        case "video_material":
          await this.fetchVideoWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "podcast":
          await this.fetchPodcastWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "text_material":
          await this.fetchTextWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "drug":
          await this.fetchMedicationWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
      }
      await this.getRecommendations(this.user.id);
    },
    async favorite(method, slug, type) {
      switch (type) {
        case "video_material":
          await this.fetchVideoFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "podcast":
          await this.fetchPodcastFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "text_material":
          await this.fetchTextFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "drug":
          await this.fetchMedicationFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
      }
      await this.getRecommendations(this.user.id);
    },
    openFilter() {
      bus.$emit("openFilter");
    },
  },
  mounted() {
    if (this.user && Object.keys(this.user).length) {
      this.getRecommendations(this.user.id);
    }
  },
  watch: {
    user() {
      if (
        this.user &&
        Object.keys(this.user).length &&
        this.recommendations &&
        !Object.keys(this.recommendations).length
      ) {
        this.getRecommendations(this.user.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.medication-item {
  height: 100%;
}

.materials {
  width: 100%;
  min-height: 100%;
  background-color: #f8f8f8;
  padding-left: 32px;
  padding-top: 48px;
  padding-bottom: 80px;
  z-index: auto;
  position: relative;
  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }

  &__filter-button {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__item-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
  }
}

.materials-page {
  &__load {
    width: 175px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }
}

.favorites {
  background-color: #f8f8f8;
  padding-left: 32px;
  padding-top: 48px;
  padding-bottom: 80px;
  z-index: 1000;
  position: relative;
  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
  }
  &__descr {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__btn {
    max-width: fit-content;
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }
}
</style>
<style lang="scss">
.materials {
  & .preloader {
    width: 100%;
    height: calc(100% - 48px - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;

    & .lds-ring {
      width: 100px;
      height: 100px;

      & div {
        border-width: 6px;
        border-color: #830051 transparent transparent transparent;
      }
    }
  }
}
</style>
<template>
  <div>
    <div
      class="materials-filter"
      :class="{ 'materials-filter_opened': opened }"
    >
      <div
        class="button button_empty-pink d-inline-flex px-5 mb-8 d-xl-none"
        @click="openFilter(false)"
      >
        <svg
          class="mr-2"
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1.5L1.5 9L9 16.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
        Назад
      </div>
      <div class="materials-filter__caption d-xl-none">Фильтры</div>
      <div class="materials-filter__wrap mb-6">
        <div
          class="materials-filter__item"
          :class="{
            'materials-filter__item_active': activeFilters.includes(0),
          }"
        >
          <div class="materials-filter__item-head" @click="toggleFilter(0)">
            <div class="materials-filter__item-title">
              Мои терапевтические области
              <span class="materials-filter__item-title-count">{{
                currentNosologys.length
              }}</span>
            </div>
            <div class="materials-filter__item-arrow">
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.2789 9.23145L12.7788 16.7314L5.27895 9.23145"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div class="materials-filter__item-body">
            <div class="materials-filter__nosologys">
              <label
                class="materials-filter__nosology"
                :class="{
                  'materials-filter__nosology_active':
                    currentNosologys.includes(nosology.id),
                }"
                v-for="nosology in formattedNosologys"
                :key="nosology.id"
                :for="`nos_${nosology.id}`"
              >
                <div class="materials-filter__nosology-icon-container">
                  <img
                    :src="formattedImageList(nosology, 'list')"
                    class="materials-filter__nosology-icon"
                  />
                </div>
                <div
                  class="materials-filter__nosology-title"
                  v-html="nosology.title"
                ></div>
                <Checkbox
                  :id="`nos_${nosology.id}`"
                  :type="'checkbox'"
                  :inputValue="nosology.id"
                  v-model="currentNosologys"
                />
              </label>
            </div>
          </div>
        </div>
        <div
          class="materials-filter__item"
          :class="{
            'materials-filter__item_active':
              activeFilters.includes(1) && formattedDiseases.count > 0,
          }"
        >
          <div class="materials-filter__item-head" @click="toggleFilter(1)">
            <div class="materials-filter__item-title">
              Мои нозологии
              <span class="materials-filter__item-title-count">{{
                formattedDiseases.count
              }}</span>
            </div>
            <div class="materials-filter__item-arrow">
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.2789 9.23145L12.7788 16.7314L5.27895 9.23145"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div class="materials-filter__item-body">
            <div class="materials-filter__diseases">
              <div
                class="materials-filter__disease"
                v-for="nosology in formattedDiseases.list"
                :key="nosology.id"
              >
                <div
                  class="materials-filter__disease-title"
                  v-html="nosology.title"
                ></div>
                <div class="materials-filter__disease-list">
                  <label
                    class="materials-filter__disease-item"
                    v-for="disease in nosology.childrens"
                    :key="disease.id"
                    :for="`dis_${disease.id}`"
                  >
                    <div
                      class="materials-filter__disease-item-title"
                      v-html="disease.title"
                    ></div>
                    <Checkbox
                      :id="`dis_${disease.id}`"
                      :type="'checkbox'"
                      :inputValue="disease.id"
                      v-model="currentDiseases"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link
        :to="{ name: 'MaterialsSettings' }"
        class="
          button button_gray
          mb-12 mb-md-6
          d-inline-flex
          px-6  
          materials-filter__settings
        "
        >Настроить Мой AZ-MOST</router-link
      >
      <br />
      <div
        class="
          button button_pink
          d-inline-flex
          px-10
          mb-8
          d-xl-none
          materials-filter__confirm
        "
        @click="openFilter(false)"
      >
        Применить фильтры
      </div>
    </div>
    <div
      class="overlay d-xl-none"
      v-if="opened"
      @click="openFilter(false)"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Checkbox from "../../form-elements/Checkbox.vue";
import { bus } from "@/main";
export default {
  name: "MaterialsFilter",
  components: { Checkbox },
  data: () => ({
    // currentNosologys: [],
    // currentDiseases: [],
    activeFilters: [],
    opened: false,
  }),
  computed: {
    ...mapGetters([
      "Nosologys",
      "user",
      "materials_therapeutic_areas",
      "materials_disease",
    ]),
    formattedNosologys() {
      return this.Nosologys.filter((el) => this.userNosologys.includes(el.id));
    },
    formattedCurrentNosologys() {
      return this.Nosologys.filter((el) =>
        this.currentNosologys.includes(el.id)
      );
    },
    userNosologys() {
      return this.user.specialities.map((el) => el.id);
    },
    userDiseases() {
      return this.user.diseases.map((el) => el.id);
    },
    formattedDiseases() {
      let onlyCurrent = this.Nosologys.filter((el) =>
        this.currentNosologys.includes(el.id)
      );
      let diseaseList = [];
      let count = 0;
      if (onlyCurrent.length) {
        onlyCurrent.forEach((item) => {
          let childrens = item.links.filter(
            (el) => el.active && this.userDiseases.includes(el.id)
          );
          if (childrens.length) {
            count += childrens.filter((el) =>
              this.materials_disease.includes(el.id)
            ).length;
            diseaseList.push({
              title: item.title,
              id: item.id,
              childrens: childrens,
            });
          }
        });
      }
      return { list: diseaseList, count: count };
    },
    currentNosologys: {
      get() {
        return this.$store.state.materialsFilter.materialsNosology;
      },
      set(value) {
        this.$store.commit("updateMatNos", value);
      },
    },
    currentDiseases: {
      get() {
        return this.$store.state.materialsFilter.materialsDisease;
      },
      set(value) {
        this.$store.commit("updateMatDis", value);
      },
    },
  },
  methods: {
    ...mapActions(["fetchNosologys"]),
    ...mapMutations(["updateMatNos", "updateMatDis"]),
    formattedImageList(item, tag) {
      return this.$root.host + item.images?.find((el) => el.tag === tag)?.image;
    },
    toggleFilter(id) {
      let index = this.activeFilters.indexOf(id);
      if (index !== -1) {
        this.activeFilters.splice(index, 1);
      } else {
        this.activeFilters.push(id);
      }
    },
    openFilter(state) {
      this.opened = state || !this.opened;
      bus.$emit("scrollLock", this.opened);
    },
  },
  mounted() {
    if (this.Nosologys && this.Nosologys.length) {
      this.fetchNosologys();
    }
    if (this.user && Object.keys(this.user).length) {
      if(!this.currentNosologys.length) {
        this.currentNosologys = this.user.specialities.map((el) => el.id);
      }
      if(!this.currentDiseases.length) {
        this.currentDiseases = this.user.diseases.map((el) => el.id);
      }
      
    }
    bus.$on("openFilter", this.openFilter);
  },
  watch: {
    user() {
      if(!this.currentNosologys.length) {
        this.currentNosologys = this.user.specialities.map((el) => el.id);
      }
      if(!this.currentDiseases.length) {
        this.currentDiseases = this.user.diseases.map((el) => el.id);
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(31, 31, 31, 0.2);
}
.materials-filter {
  @media screen and (max-width: 1220px) {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70vw;
    max-height: 100vh;
    height: 100%;
    z-index: 999999999;
    background-color: #fff;
    margin-top: 0;
    padding: 32px 24px;
    transition: 0.3s;

    &__wrap {
      max-height: 50vh;
      overflow-y: auto;
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100vw;

    &__settings {
      width: 100%;
    }

    &__confirm {
      width: 100%;
    }
  }

  &_opened {
    right: 0;
  }
  margin-top: 48px;

  &__caption {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #1f1f1f;
  }

  &__item {
    margin-bottom: 24px;
    transition: 0.3s;
    &:last-child {
      margin-bottom: 0;
    }
    &-head {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &:hover {
        .materials-filter {
          &__item {
            &-title {
              color: #830051;
              &-count {
                color: #fff;
                background-color: #830051;
              }
            }
          }
        }
      }
    }

    &-arrow {
      transition: 0.3s;
      transform: rotate(0);
      margin-left: 16px;

      @media screen and (max-width: 1220px) {
        display: none;
      }
    }

    &-title {
      margin-right: auto;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #1f1f1f;
      transition: 0.3s;

      @media screen and (max-width: 1220px) {
        font-size: 23px;
        line-height: 29px;
      }

      @media screen and (max-width: 1220px) {
        font-size: 18px;
        line-height: 26px;
      }

      &-count {
        margin-left: 4px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #1f1f1f;
        background: #ebefee;
        flex-shrink: 0;
        transform: translateY(-3px);
        transition: 0.3s;
      }
    }

    &-body {
      margin-top: 16px;
      overflow: hidden;
      max-height: 0;
      transition: 0.3s;

      @media screen and (max-width: 1220px) {
        max-height: none;
      }
    }
  }

  &__item_active {
    margin-bottom: 48px;
    .materials-filter {
      &__item {
        &-body {
          max-height: 999999px;
        }

        &-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__nosology {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    & .checkbox {
      flex-shrink: 0;
      margin-left: 16px;
    }

    &-icon-container {
      width: 40px;
      height: 40px;
      background: radial-gradient(
        46.35% 35.31% at 51.82% 64.77%,
        #f4e8ce 0%,
        #d2d2d2 100%
      );
      flex-shrink: 0;
      border: 2px solid transparent;
      border-radius: 4px;
      padding: 4px;
    }

    &:hover {
      .materials-filter {
        &__nosology {
          &-icon-container {
            background: rgba(131, 0, 81, 0.09);
          }
          &-title {
            color: #830051;
          }
        }
      }
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &-title {
      margin-left: 8px;
      margin-right: auto;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #1f1f1f;
    }
  }

  &__nosology_active {
    .materials-filter__nosology {
      &-icon-container {
        border-color: #830051;
      }

      &-title {
        color: #830051;
      }
    }
  }

  &__disease {
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    &-title {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #830051;
    }

    &-list {
      padding-bottom: 16px;
      border-bottom: 1px solid #d2d2d2;
      margin-bottom: 16px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    &-item {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 16px;
      &:hover {
        .materials-filter {
          &__disease {
            &-item-title {
              color: #830051;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      & .checkbox {
        margin: auto 0 auto 16px;
      }

      &-title {
        margin-right: auto;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #3c4242;
        transition: 0.3s;
      }
    }
  }
}
</style>

<style>
  .materials-filter__nosology:hover .checkbox__wrap {
     box-shadow: 0px 0px 3px 1px rgba(208, 0, 111, 0.2);
  }

  .materials-filter__disease:hover .checkbox__wrap {
     box-shadow: 0px 0px 3px 1px rgba(208, 0, 111, 0.2);
  }
</style>